.authbody {
  position: relative;
  height: 30vh;
  width: 100%;
  color: black;
  z-index: 1;
}
.title_back {
  height: 40vh;
}
.title {
  font-size: 100px;
  font-family: "Oleo Script Swash Caps", cursive;
  font-style: italic;
  width: 100%;
  text-align: center;
  font-weight: 700;
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 600px) {
  .title {
    font-size: 60px;
  }
}

.authform {
  padding: 30px;
  min-width: 200px;
  max-width: 500px;
  width: 100%;
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  border-radius: 15px;
}

.btnlogin {
  max-width: 300px;
  width: 100%;
  transition: all linear 0.2s;
  border: none;
  font-size: 20px;
  margin-bottom: 10px;
}
.btnlogin:hover {
  color: black;
  background-color: white;
}
.resetp {
  font-size: 20px;
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.starback {
  position:absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 0;
}
.star {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  animation: animate 8s ease-in-out infinite,
  backgroundmove 16s linear infinite;
}
.star1 {
  animation-delay: -0s;
  background: url(../../mystars/star1.png);
}
.star2 {
  animation-delay: -1s;
  background: url(../../mystars/star2.png);
}
 .star3 {
  animation-delay: -2s;
  background: url(../../mystars/star3.png);
}
 .star4 {
  animation-delay: -3s;
  background: url(../../mystars/star4.png);
}

@keyframes animate {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    opacity: 0.2;
  }
  10%,30%,50%,70%,90%
  {
    opacity: 1;
  }
}

@keyframes backgroundmove {
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(1.2);
  }
}
